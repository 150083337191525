.flowbox-container{
    width: 100%;
    align-self: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
}

@media only screen and (max-width: 768px) {
    .flowbox-container{
        display: none;
    }
}

.flowbox-top{
    width: 100%;
    min-height: 300px;
    border-width: 30px 30px 30px 0px;
    border-style: solid;
    border-color: #2d234a;
    border-radius: 0px 0px 50px 0px;
    background-color: white;

    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    flex: 0 1 auto;
}

.flowbox-top .contentHolder{
    padding-left: 20px; 
}

.flowbox-top .imgHolder .myImg{
    border-radius: 0 0 20px 0;
}

.flowbox-left{
    width: 100%;
    min-height: 300px;
    border-color: #2d234a;
    border-style: solid;
    border-width: 0px 0px 30px 30px;
    border-radius: 0px 50px 0px 50px;
    background-color: white;

    display: flex;
    justify-content: space-between;
    flex-direction: row;

}

.flowbox-left .imgHolder .myImg{
    border-radius: 0 0 0 20px;
}


.flowbox-right{
    width: 100%;
    min-height: 300px;
    border-color: #2d234a;
    border-style: solid;
    border-width: 0px 30px 30px 0px;
    border-radius: 5% 0px 50px 0px;
    background-color: white;

    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

}

.flowbox-right .contentHolder{
    padding-left: 20px; 
}

.flowbox-right .imgHolder .myImg{
    border-radius: 0 0 20px 0;
}


.imgHolder{
    width: 42%;
    overflow: hidden;
    height: auto;
    max-height: 500px;
}
.contentHolder{
    margin: 20px;
    width: 52%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.contentHolder .title{
    font-size: xx-large;
    color: #2d234a;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0px;

}


.contentHolder .subtitle{
    font-size: large;
    margin-bottom: 5px;
    color: #2d234a;
    font-weight: 600;
}

.contentHolder .subtitle span{
    font-size: medium;
    color: black;
    font-weight: normal;
}

.contentHolder .content{
    font-size: normal;
}

.myImg{
    width: 100%;
    height: 100%;
}