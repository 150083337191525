@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap");

@import "./css/flowbox.css";
@import "./css/flowmob.css";
@import "./css/aboutBox.css";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafaff;
  font-weight: 400;
  overflow-x: hidden;
}

main {
  background: #151418;
  background-image: url('../public/blob-haikei.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  transition: 0.2s ease-in;

}

ul,
ol {
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
video {
  display: block;
  max-width: 100%;
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  outline: none;
}

.btn-shadow {
  box-shadow: 0px 2px 5px rgba(160, 170, 180, 0.6);

}

.btn-shadow:hover {
  box-shadow: 0px 3px 8px rgba(160, 170, 180, 0.9);
}

.PrimaryBtn {
  padding: 14px 22px;
  border: none;
  background: rgb(57, 134, 250);
  color: #f6f6f6;
}

.PrimaryBtn2 {
  padding: 14px 22px;
  border: none;
  background: rgb(57, 134, 250);
  color: #f6f6f6;
}

.SecondaryBtn {
  padding: 10px 18px;
  border: 2px solid rgb(57, 134, 250);
  color: #010606;
  margin-right: 1rem;
}

.PrimaryBtn:hover {
  background: rgb(18, 111, 250);
}

.SecondaryBtn:hover {
  background: #e7f1fa;
  color: #000
}

.Container {
  /* max-width: 1000px; */
  padding-right: 1rem;
  padding-left: 1rem;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .Container {
    width: 97%;
  }
}

.BigCard {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;
}

.AboutCard {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: rgba(37, 26, 26, 0.5) 0px 2px 4px;
}

@media only screen and (min-width: 1320px){
  .AboutCard{
      display: none;
  }
}

.AboutContainer {
  padding-bottom: 1rem;
  padding-top: 2rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  margin-right: 0;
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  min-width: 85%;

  @media only screen and (max-width: 768px) {
    display: block;
    align-self: center;

  }


  @media (min-width: 1200px) {
    max-width: 1000px;
  }

}

.Imagebox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1.4rem;
  background-color: #2d234a;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  border-radius: 1rem;
  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;

  }
}

.imageCaption {
  color: white;
  text-align: justify;
  align-self: flex-start;
}

.TimelineBox {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background: #fff;
  box-shadow: rgba(37, 26, 26, 0.5) 0px 2px 4px;


  @media screen and (max-width: 1000px) {
    display: none;

  }
}

.AboutBio {
  text-align: justify;
  max-width: "90%";
  margin: 0 auto;
  color: #2d234a;
  line-height: 1.6;
}

.tagline2 {
  text-align: left;
  max-width: 650px;
  margin: 0;
  color: #2d234a;
  line-height: 1.6;
}

.tagline2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.SectionTitle {
  font-size: 28px;
  color: #2d234a;
  font-weight: 700;
  margin-bottom: 3.5rem;
}

@media only screen and (max-width: 768px) {
  .SectionTitle {
    margin-bottom: 1rem;
  }
}

.SubsectionTitle {
  font-size: 24px;
  color: #2d234a;
  font-weight: 700;
}

.ProjectWrapper {
  margin-top: -1rem;
}

.BigCard h2 {
  align-self: center;
  color: #2d234a;
  font-size: 1.5rem;
  padding: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .BigCard h2 {
    font-size: 1rem;
    padding: 1.5rem;
    font-weight: bold;
  }
}

/* ------------------------------------ Media for Container */
@media (max-width: 1000px) {
  .Container {
    padding: 0;
  }

}

.techImg {
  height: 50px;
  width: auto;
  padding-bottom: 5px;
}

@media only screen and (max-width:768px){
  .techImg {
    height: 35px;

  }

}

.aboutHead {
  font-size: xx-large;
  font-weight: 700;
  color: #2d234a;
  padding-bottom: 1vw;
}

.aboutSummary {
  font-size: large;
  text-align: justify;
}

.navButton {
  display: flex;
  align-items: center;
  margin-right: 24px;


  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.HeroContainer {
  padding-bottom: 2rem;
  padding-top: 4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px){
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 4rem;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1000px;
  }

}

.ExperienceCard {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  box-shadow: rgba(37, 26, 26, 0.5) 0px 2px 4px;
}

.ExperienceContainer{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

.hideMob{
  display: block;
}

@media only screen and (max-width: 768px){
  .hideMob{
    display: none;
  }
}

.slidesHolder{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 3rem;

  width: 67vw;
  height: 37vw;
  @media only screen and (max-width: 1200px) {
    width: 78vw;
    height: 42vw;
  }

  @media only screen and (max-width: 768px){
    display: none;
  }

}

swiper-container {
  width: 67vw;
  height: 37vw;
  @media screen and (max-width: 1200px) {
    width: 78vw;
    height: 42vw;
  }
}

swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: black;
  /* border: 1px solid black; */
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;
  background-color: white;



}

a{
  color: blue;
}
a :hover{
  text-decoration: underline;
}

.eksrul{
  display: none !important;
  position: relative !important;
}