.naboutContainer{
    width: 100%;
    align-self: center;
    margin-bottom: 3.5rem;
    position: relative;
    background-color: white;

    display: grid;
    grid-template-columns: 30px 1fr 1fr 1fr 30px;
    grid-template-rows: 30px 1fr 1fr 1fr 30px;
    grid-template-areas: 
    ". . . border1 border1"
    ". cn cn cn border2"
    ". cn cn cn . "
    "border3 cn cn cn . "
    "border4 border4 . . .";

}

@media only screen and (max-width: 768px) {
    .naboutContainer{
        grid-template-columns: 18px 1fr 1fr 1fr 18px;
        grid-template-rows: 18px 1fr 1fr 1fr 18px; 
    }
}

.nborder1{
    background-color: #2d234a;
    grid-area: border1;
}
.nborder2{
    background-color: #2d234a;
    grid-area: border2;
}
.nborder3{
    background-color: #2d234a;
    grid-area: border3;
}
.nborder4{
    background-color: #2d234a;
    grid-area: border4;
}


/* @media only screen and (max-width: 1320px){
    .naboutContainer{
        display: none;
    }
} */

/* @media only screen and (min-width: 1620px){
    .naboutContainer{
        min-height: 1900px;
    }
} */

.borderBoxOne{
    border-width: 30px 30px 0px 0px;
    border-style: solid;
    border-color: #2d234a;
    background-color: white;
    grid-area: one;
}

.borderBoxTwo{
    border-width: 0px 0px 30px 30px;
    border-style: solid;
    border-color: #2d234a;
    background-color: white;
    grid-area: two;
}

.naboutContentBox{
    /* position: absolute;
    height: calc(100% - 60px);
    width: calc(100% - 60px); */
    grid-area: cn;
    top: 30px;
    left: 30px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 0.2fr 1fr 0.7fr;
    grid-template-areas: 
    "img1 para1"
    "para2 para1"
    "para2 img2"
    "para2 para3";
}

@media only screen and (max-width: 768px){
    .naboutContentBox{
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding: 30px 10px;
    }
}


.nimg1{
    height: 100%;
    width: 100%;
    grid-area: img1;
    background-color: white;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "mainarea";
    justify-content: center;
    align-items: center;
}

.nimg2{
    height: 100%;
    width: 100%;
    grid-area: img2;
    background-color: white;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "mainarea";
    justify-content: center;
    align-items: center;
}

.nimgHolder1{
    margin: 20% 10%;
    height: 60%;
    width: 80%;
    grid-area: mainarea;
    background-color: #2d234a;
    transform: rotate(-4deg);
    transition: 0.3s;
}

.nimgHolder1.hover{
    height: calc(60% * 1.05);
    width: calc(80% * 1.05);
    margin: calc(20% * 0.925) calc(10% * 0.90);
    transition: 0.3s;
    transform: rotate(4deg);
}

.nimgHolder3{
    margin: 20% 10%;
    height: 60%;
    width: 80%;
    grid-area: mainarea;
    background-color: #2d234acc;
    transform: rotate( -7deg);
    transition: 0.3s;
}

.nimgHolder3.hover{
    height: calc(60% * 1.05);
    width: calc(80% * 1.05);
    margin: calc(20% * 0.925) calc(10% * 0.90);
    transition: 0.3s;
    transform: rotate(7deg) !important;
    transition: 0.3s;
}

.nimgHolder2{
    margin: 20% 10%;
    height: 60%;
    width: 80%;
    grid-area: mainarea;
    background-color: white;
    transform: rotate(0deg);
    transition: 0.3s;
}

.nimgHolder2.hover{
    height: calc(60% * 1.05);
    width: calc(80% * 1.05);
    margin: calc(20% * 0.925) calc(10% * 0.90);
    transition: 0.3s;
    /* transform: rotate(7deg) !important; */
    transition: 0.3s;
}

.npara1{
    margin-left: 10%;
    margin-right: 5%;
    margin-top: 15%;
    min-height: 100%;
    display: flex;
    grid-area: para1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.npara1 .title{
    font-size: xx-large;
    color: #2d234a;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0px;
}

.npara1 .subtitle{
    font-size: x-large;
    margin-bottom: 5px;
    color: #2d234a;
    font-weight: 600;
}

.npara1 .content{
    font-size: normal;
}

.npara2{
    margin-left: 10%;
    margin-right: 5%;
    margin-top: 7.5%;
    grid-area: para2;

    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.npara2 .title{
    font-size: xx-large;
    color: #2d234a;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0px;
}

.npara2 .subtitle{
    font-size: x-large;
    margin-bottom: 5px;
    color: #2d234a;
    font-weight: 600;
}

.npara2 .content{
    font-size: normal;
}

.npara3{
    margin-left: 10%;
    margin-right: 5%;
    grid-area: para3;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.npara3 .subtitle{
    font-size: x-large;
    margin-bottom: 5px;
    color: #2d234a;
    font-weight: 600;
}

.npara3 .content{
    font-size: normal;
}

@media only screen and (max-width: 768px){
    .npara1{
        order: 1;
        margin: 30px 20px;
    }

    .nimg1{
        order:2;
    }


    .npara2{
        order: 3;
        margin: 30px 20px;
    }

    .nimg2{
        order: 4;
    }

    .npara3{
        order: 5;
        margin: 30px 20px;
    }

}