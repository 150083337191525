.flowMobHolder{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 1rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;
}

@media only screen and (max-width: 768px){
    .flowMobHolder{
        padding-left: .5rem;


    }
}

.flowmobContentHolder{
    margin: 20px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.flowmobContentHolder .title{
    font-size: xx-large;
    color: #2d234a;
    font-weight: 700;

}

.flowmobContentHolder .subtitle{
    font-size: x-large;
    margin-bottom: 10px;
    color: #2d234a;
    font-weight: 600;
}

.flowmobContentHolder .content{
    font-size: large;
}

.flowmobBox .contentHolder{
    width: 100% !important;
    gap: 10px !important;
}

.flowmobBox .contentHolder .title{
    font-size: x-large !important;
}

@media only screen and (min-width: 768px){
    .flowMobHolder{
        display: none;
    }
}